import * as Types from '../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShouldAcceptStripeDebitAgreementQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetShouldAcceptStripeDebitAgreementQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, sellerConfig?: { __typename?: 'SellerConfig', shouldAcceptStripeDebitAgreement: boolean } | null } | null };

export type AcceptStripeDebitAgreementMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type AcceptStripeDebitAgreementMutation = { __typename?: 'Mutation', acceptStripeDebitAgreement: { __typename?: 'AcceptStripeTermsOfServicesPayload', ok: boolean } };


export const GetShouldAcceptStripeDebitAgreementDocument = gql`
    query GetShouldAcceptStripeDebitAgreement {
  viewer {
    id
    sellerConfig {
      shouldAcceptStripeDebitAgreement
    }
  }
}
    `;

/**
 * __useGetShouldAcceptStripeDebitAgreementQuery__
 *
 * To run a query within a React component, call `useGetShouldAcceptStripeDebitAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShouldAcceptStripeDebitAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShouldAcceptStripeDebitAgreementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShouldAcceptStripeDebitAgreementQuery(baseOptions?: Apollo.QueryHookOptions<GetShouldAcceptStripeDebitAgreementQuery, GetShouldAcceptStripeDebitAgreementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShouldAcceptStripeDebitAgreementQuery, GetShouldAcceptStripeDebitAgreementQueryVariables>(GetShouldAcceptStripeDebitAgreementDocument, options);
      }
export function useGetShouldAcceptStripeDebitAgreementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShouldAcceptStripeDebitAgreementQuery, GetShouldAcceptStripeDebitAgreementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShouldAcceptStripeDebitAgreementQuery, GetShouldAcceptStripeDebitAgreementQueryVariables>(GetShouldAcceptStripeDebitAgreementDocument, options);
        }
export function useGetShouldAcceptStripeDebitAgreementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetShouldAcceptStripeDebitAgreementQuery, GetShouldAcceptStripeDebitAgreementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShouldAcceptStripeDebitAgreementQuery, GetShouldAcceptStripeDebitAgreementQueryVariables>(GetShouldAcceptStripeDebitAgreementDocument, options);
        }
export type GetShouldAcceptStripeDebitAgreementQueryHookResult = ReturnType<typeof useGetShouldAcceptStripeDebitAgreementQuery>;
export type GetShouldAcceptStripeDebitAgreementLazyQueryHookResult = ReturnType<typeof useGetShouldAcceptStripeDebitAgreementLazyQuery>;
export type GetShouldAcceptStripeDebitAgreementSuspenseQueryHookResult = ReturnType<typeof useGetShouldAcceptStripeDebitAgreementSuspenseQuery>;
export type GetShouldAcceptStripeDebitAgreementQueryResult = Apollo.QueryResult<GetShouldAcceptStripeDebitAgreementQuery, GetShouldAcceptStripeDebitAgreementQueryVariables>;
export const AcceptStripeDebitAgreementDocument = gql`
    mutation AcceptStripeDebitAgreement {
  acceptStripeDebitAgreement {
    ok
  }
}
    `;
export type AcceptStripeDebitAgreementMutationFn = Apollo.MutationFunction<AcceptStripeDebitAgreementMutation, AcceptStripeDebitAgreementMutationVariables>;

/**
 * __useAcceptStripeDebitAgreementMutation__
 *
 * To run a mutation, you first call `useAcceptStripeDebitAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptStripeDebitAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptStripeDebitAgreementMutation, { data, loading, error }] = useAcceptStripeDebitAgreementMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptStripeDebitAgreementMutation(baseOptions?: Apollo.MutationHookOptions<AcceptStripeDebitAgreementMutation, AcceptStripeDebitAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptStripeDebitAgreementMutation, AcceptStripeDebitAgreementMutationVariables>(AcceptStripeDebitAgreementDocument, options);
      }
export type AcceptStripeDebitAgreementMutationHookResult = ReturnType<typeof useAcceptStripeDebitAgreementMutation>;
export type AcceptStripeDebitAgreementMutationResult = Apollo.MutationResult<AcceptStripeDebitAgreementMutation>;
export type AcceptStripeDebitAgreementMutationOptions = Apollo.BaseMutationOptions<AcceptStripeDebitAgreementMutation, AcceptStripeDebitAgreementMutationVariables>;