import { createContext } from 'react'

import { SellerConsentDialog } from '@/components/SellerConsent/SellerConsentDialog'
import { StripeAutoDebitAgreementModal } from '@/components/Stripe/StripeAutoDebitAgreement/StripeAutoDebitAgreementModal/StripeAutoDebitAgreementModal'
import { StripeTermsOfServicesModal } from '@/components/Stripe/StripeTermsOfServicesModal/StripeTermsOfServicesModal'
import { NotificationContainer } from '@/components/ui/Notification/Notification'

import { GenericErrorMessageContainer as GenericErrorMessage } from '../components/Error/GenericErrorMessage/GenericErrorMessage.container'
import { NetworkManagerContainer as NetworkManager } from '../components/Network/NetworkManager/NetworkManager.container'

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import type { ReactNode } from 'react'

interface LayoutProviderState {}

const LayoutProviderContext = createContext<LayoutProviderState>({} as LayoutProviderState)

export interface LayoutProviderProps {
  children: ReactNode
  apolloClient: ApolloClient<NormalizedCacheObject>
}

export const LayoutProvider = ({ children, apolloClient }: LayoutProviderProps) => {
  const context: LayoutProviderState = {}

  return (
    <LayoutProviderContext.Provider value={context}>
      {children}
      <NotificationContainer />
      <GenericErrorMessage />
      <NetworkManager apolloClient={apolloClient} />
      <StripeTermsOfServicesModal />
      <StripeAutoDebitAgreementModal />
      <SellerConsentDialog />
    </LayoutProviderContext.Provider>
  )
}

// export const useLayout = () => useContext(LayoutProviderContext)
