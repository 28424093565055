import { createSlice } from '@reduxjs/toolkit'

export enum WS_STATUS {
  CONNECTED = 'connected',
  CLOSED = 'closed',
  CONNECTING = 'connecting',
}

export const MAX_TIME_BEFORE_RETRY = 30000

type ActionsPayload = {
  setWebsocketStatus: { payload: WS_STATUS }
  setNextTimeBeforeRetry: { payload: number }
}

const initialState = {
  status: WS_STATUS.CONNECTED,
  showWebsocketStatus: false,
  nextTimeBeforeRetry: 0,
  showNetworkError: false,
  previousStatus: WS_STATUS.CONNECTED,
}

const networkSlice = createSlice({
  name: 'network',
  reducers: {
    setWebsocketStatus: (state, { payload }: ActionsPayload['setWebsocketStatus']) => {
      state.previousStatus = state.status
      state.status = payload
    },
    setNextTimeBeforeRetry: (state, { payload }: ActionsPayload['setNextTimeBeforeRetry']) => {
      state.nextTimeBeforeRetry = payload
    },
    showWebsocketStatus: (state) => {
      state.showWebsocketStatus = true
    },
    hideWebsocketStatus: (state) => {
      state.showWebsocketStatus = false
    },
    showNetworkError: (state) => {
      state.showNetworkError = true
    },
  },
  initialState,
})

export const {
  setWebsocketStatus,
  showWebsocketStatus,
  hideWebsocketStatus,
  setNextTimeBeforeRetry,
  showNetworkError,
} = networkSlice.actions

export default networkSlice.reducer
