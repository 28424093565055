import Config from '@/config/config'
import { getStickyVersion } from '@/network/common/stickyVersion'
import { uniqueId } from '@/util/lodash-replacer'

let voggtUA: string

// The following is intended to be used as a unique identifier for a given page load
// It may be used by the backend to identify multiple connections from the same user,
// either when using several tabs, or maybe due to bugs
const pageLoadUniqueId = uniqueId()

export const getVoggtUserAgent = async () => {
  if (voggtUA) {
    return voggtUA
  }

  const sv = await getStickyVersion()

  // The following combine the fingerprint (aka sticky version) with a random unique id generated on page load
  // This is going to be used to debug WS issues where a given user seems to open multiple WS connections
  const sv2 = `${sv}-${pageLoadUniqueId}`

  const { VITE_APP_BITS_ENV, PLATFORM, PLATFORM_VERSION, NETWORK_LIB, NETWORK_LIB_VERSION } = Config

  voggtUA = `Voggt ${VITE_APP_BITS_ENV} ${PLATFORM}/${PLATFORM_VERSION} (${NETWORK_LIB}/${NETWORK_LIB_VERSION}) [${sv2}]`
  return voggtUA
}

export const getAdditionalHeaders = async () => {
  return {
    'voggt-user-agent': await getVoggtUserAgent(),
    'x-voggt-sticky-version': (await getStickyVersion()) || '',
  }
}
