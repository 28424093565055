import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetSellerDataConsentMutationVariables = Types.Exact<{
  input: Types.SetSellerDataConsentInput;
}>;


export type SetSellerDataConsentMutation = { __typename?: 'Mutation', setSellerDataConsent: { __typename?: 'SetSellerDataConsentPayload', ok: boolean } };

export type DismissSellerDataConsentMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DismissSellerDataConsentMutation = { __typename?: 'Mutation', dismissSellerDataConsent: { __typename?: 'DismissSellerDataConsentPayload', ok: boolean } };


export const SetSellerDataConsentDocument = gql`
    mutation SetSellerDataConsent($input: SetSellerDataConsentInput!) {
  setSellerDataConsent(input: $input) {
    ok
  }
}
    `;
export type SetSellerDataConsentMutationFn = Apollo.MutationFunction<SetSellerDataConsentMutation, SetSellerDataConsentMutationVariables>;

/**
 * __useSetSellerDataConsentMutation__
 *
 * To run a mutation, you first call `useSetSellerDataConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSellerDataConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSellerDataConsentMutation, { data, loading, error }] = useSetSellerDataConsentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSellerDataConsentMutation(baseOptions?: Apollo.MutationHookOptions<SetSellerDataConsentMutation, SetSellerDataConsentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSellerDataConsentMutation, SetSellerDataConsentMutationVariables>(SetSellerDataConsentDocument, options);
      }
export type SetSellerDataConsentMutationHookResult = ReturnType<typeof useSetSellerDataConsentMutation>;
export type SetSellerDataConsentMutationResult = Apollo.MutationResult<SetSellerDataConsentMutation>;
export type SetSellerDataConsentMutationOptions = Apollo.BaseMutationOptions<SetSellerDataConsentMutation, SetSellerDataConsentMutationVariables>;
export const DismissSellerDataConsentDocument = gql`
    mutation DismissSellerDataConsent {
  dismissSellerDataConsent {
    ok
  }
}
    `;
export type DismissSellerDataConsentMutationFn = Apollo.MutationFunction<DismissSellerDataConsentMutation, DismissSellerDataConsentMutationVariables>;

/**
 * __useDismissSellerDataConsentMutation__
 *
 * To run a mutation, you first call `useDismissSellerDataConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissSellerDataConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissSellerDataConsentMutation, { data, loading, error }] = useDismissSellerDataConsentMutation({
 *   variables: {
 *   },
 * });
 */
export function useDismissSellerDataConsentMutation(baseOptions?: Apollo.MutationHookOptions<DismissSellerDataConsentMutation, DismissSellerDataConsentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissSellerDataConsentMutation, DismissSellerDataConsentMutationVariables>(DismissSellerDataConsentDocument, options);
      }
export type DismissSellerDataConsentMutationHookResult = ReturnType<typeof useDismissSellerDataConsentMutation>;
export type DismissSellerDataConsentMutationResult = Apollo.MutationResult<DismissSellerDataConsentMutation>;
export type DismissSellerDataConsentMutationOptions = Apollo.BaseMutationOptions<DismissSellerDataConsentMutation, DismissSellerDataConsentMutationVariables>;