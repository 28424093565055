import { useEffect, useState } from 'react'

import { useAppSelector } from '@/reducers'
import { WS_STATUS } from '@/reducers/network'

import { NetworkManager } from './NetworkManager'

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'

interface NetworkManagerContainerProps {
  apolloClient: ApolloClient<NormalizedCacheObject>
}

export const NetworkManagerContainer = ({ apolloClient }: NetworkManagerContainerProps) => {
  const { status, showWebsocketStatus, previousStatus } = useAppSelector((store) => store.network)

  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (status === WS_STATUS.CONNECTED && previousStatus === WS_STATUS.CONNECTING) {
      if (isFirstRender) {
        setIsFirstRender(false)
      } else {
        apolloClient.refetchQueries({
          include: 'active',
        })
      }
    }
  }, [status])

  return <NetworkManager showWebsocketStatus={showWebsocketStatus} status={status} />
}
