import { createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

export const fetchSellerBillingData = createAsyncThunk(
  'fetch-seller-billing-data',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const {
      authentication: { token },
    } = getState() as { authentication: { token: string } }

    try {
      const { data } = await internalRequest({
        headers: { authorization: token, ...(await getAdditionalHeaders()) },
        url: `/seller/billing`,
        method: 'GET',
        dispatch,
      })

      return data
    } catch (error: any) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }
    }
  }
)
