// This file serves to replace all usage of lodash with native javascript

/**
 * Returns the object from the collection that has the minimum value of the specified key.
 * If multiple objects have the same minimum value, the first encountered one is returned.
 * @param collection - The array of objects to search through.
 * @param key - The key to compare values by within each object.
 * @returns The object with the minimum value of the specified key.
 */
export const minByKey = (collection: Record<string, any>[], key: string): Record<string, any> => {
  const select = (a: Record<string, any>, b: Record<string, any>): Record<string, any> =>
    a[key] !== undefined && b[key] !== undefined && a[key] <= b[key] ? a : b
  return collection.reduce(select, {})
}

/**
 * Sorts an array of objects based on a custom comparator function.
 * @param array - The array of objects to be sorted.
 * @param comparator - A function that defines the sort order.
 * @returns A new array containing the sorted objects.
 */
export const sortBy = <T>(array: T[], comparator: (a: T, b: T) => number): T[] => {
  return array.slice().sort(comparator)
}

/**
 * Sorts an array of objects based on the values of a specified key.
 * @param array - The array of objects to be sorted.
 * @param key - The key to compare values by within each object.
 * @returns A new array containing the sorted objects.
 */
export const sortByKey = <T>(array: T[], key: string): T[] => {
  return array.slice().sort((a, b) => {
    if ((a as Record<string, any>)[key] < (b as Record<string, any>)[key]) return -1
    if ((a as Record<string, any>)[key] > (b as Record<string, any>)[key]) return 1
    return 0
  })
}

/**
 * Returns the sum of the values calculated by the accumulator function applied to each object in the array.
 * @param array - The array of objects.
 * @param accumulator - A function that calculates the value to be summed for each object.
 * @returns The sum of the values calculated by the accumulator function.
 */
export const sumBy = <T>(array: readonly T[], accumulator: (item: T) => number): number => {
  return array.reduce((acc, obj) => {
    return acc + accumulator(obj)
  }, 0)
}

/**
 * Compares two values for deep equality.
 * @param a - The first value.
 * @param b - The second value.
 * @returns True if the values are deeply equal, false otherwise.
 */
export const isEqual = (a: any, b: any): boolean => {
  // Handle primitives and reference types
  if (a === b) return true

  // Handle null or undefined values
  if (a == null || b == null) return false

  // Handle arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false
    for (let i = 0; i < a.length; i++) {
      if (!isEqual(a[i], b[i])) return false
    }
    return true
  }

  // Handle objects
  if (typeof a === 'object' && typeof b === 'object') {
    const keysA = Object.keys(a)
    const keysB = Object.keys(b)
    if (keysA.length !== keysB.length) return false
    for (const key of keysA) {
      if (!keysB.includes(key) || !isEqual(a[key], b[key])) return false
    }
    return true
  }

  // Handle other types
  return false
}

/**
 * Generates a unique identifier.
 * @returns A unique identifier string.
 */
export const uniqueId = (): string => {
  return Math.random().toString(36)
}

/**
 * Returns a new array containing only the unique elements of the original array based on the value returned by an accumulator function.
 * @param array - The array of elements.
 * @param accumulator - A function that returns the value to filter elements by.
 * @returns A new array containing only the unique elements of the original array.
 */
export const uniqBy = <T>(array: T[], accumulator: (item: T) => any): T[] => {
  const seen = new Set()
  return array.filter((item) => {
    const value = accumulator(item)
    return seen.has(value) ? false : seen.add(value)
  })
}

/**
 * Groups the elements of an array based on the value returned by an accumulator function.
 * @param array - The array of objects to be grouped.
 * @param accumulator - A function that returns the value to group elements by within each object.
 * @returns An object containing the grouped elements.
 */
export const groupBy = <T>(array: T[], accumulator: (item: T) => any): Record<string, T[]> => {
  return array.reduce(
    (acc, obj) => {
      const value = accumulator(obj)
      if (!acc[value]) acc[value] = []
      acc[value].push(obj)
      return acc
    },
    {} as Record<string, T[]>
  )
}

/**
 * Groups the elements of an array based on the value of a specified key.
 * @param array - The array of objects to be grouped.
 * @param key - The key to group elements by within each object.
 * @returns An object containing the grouped elements.
 */
export const groupByKey = <T>(array: T[], key: string): Record<string, T[]> => {
  return array.reduce(
    (acc, obj) => {
      const value = (obj as Record<string, any>)[key]
      if (!acc[value]) acc[value] = []
      acc[value].push(obj)
      return acc
    },
    {} as Record<string, T[]>
  )
}

/**
 * Returns a new array containing only the distincts elements of the original array.
 * @param array - The array of elements to filter.
 * @returns A new array containing only the distincts elements of the original array
 */
export const uniq = <T>(array: T[]): T[] => {
  return Array.from(new Set(array))
}
