import axios from 'axios'
import axiosRetry from 'axios-retry'
import { useCallback, useEffect, useState } from 'react'

import Config from '@/config/config'
import { getNetworkDownloadSpeedInMBPS } from '@/helpers/getNetworkSpeed'
import { useAppSelector } from '@/reducers'
import { MAX_TIME_BEFORE_RETRY } from '@/reducers/network'

import { GenericErrorMessage } from './GenericErrorMessage'

export enum ERROR_REASON {
  CLIENT_SIDE = 'CLIENT_SIDE',
  SERVER_SIDE = 'SERVER_SIDE',
}
type ERROR = {
  errorMessage: string
  errorReason: ERROR_REASON | undefined
  networkSpeed?: string
}

const { VITE_APP_CLIENT_MONITORING_URL } = Config

export const GenericErrorMessageContainer = () => {
  const { nextTimeBeforeRetry, showNetworkError } = useAppSelector((store) => store.network)

  const [error, setError] = useState<ERROR | undefined>(undefined)

  const checkInternetConnection = useCallback(async (message: string) => {
    axiosRetry(axios, {
      retries: 3,
    })
    const url = VITE_APP_CLIENT_MONITORING_URL

    try {
      const result = await axios.get(url)
      const networkSpeed = await getNetworkDownloadSpeedInMBPS()
      if (result.status === 200) {
        //the seller can reach the internet but not the backend
        setError({
          errorMessage: message,
          errorReason: ERROR_REASON.SERVER_SIDE,
          networkSpeed: networkSpeed || undefined,
        })
      } else {
        setError({
          errorMessage: message,
          errorReason: ERROR_REASON.CLIENT_SIDE,
          networkSpeed: networkSpeed || undefined,
        })
      }
    } catch (error) {
      setError({
        errorMessage: message,
        errorReason: ERROR_REASON.CLIENT_SIDE,
        networkSpeed: undefined,
      })
    }
  }, [])

  // Error 0 : WS connection failed after MAX_TIME_BEFORE_RETRY
  useEffect(() => {
    if (nextTimeBeforeRetry === MAX_TIME_BEFORE_RETRY) {
      const errorMessage = `Didn't succeed to connect to the WS after all retry attempts`

      checkInternetConnection(errorMessage)
    }
  }, [nextTimeBeforeRetry])

  // Error 1 : Network error (no internet connection / backend unavailable)
  useEffect(() => {
    if (showNetworkError) {
      const errorMessage = `Couldn't reach the backend after all retry attempts`

      checkInternetConnection(errorMessage)
    }
  }, [showNetworkError])

  return <GenericErrorMessage error={error} />
}
