import './Textarea.scss'

// TODO: Add proper types?
type TextAreaProps = any

const Textarea = (props: TextAreaProps) => {
  return <textarea {...props} />
}

export default Textarea
