import { createAsyncThunk } from '@reduxjs/toolkit'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

import type { RootState } from '@/reducers'

export const fetchShippingAddress = createAsyncThunk(
  'fetch-shipping-address',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const {
      authentication: { token },
    } = getState() as RootState

    try {
      const { data } = await internalRequest({
        headers: { authorization: token, ...(await getAdditionalHeaders()) },
        method: 'GET',
        url: '/shipping-addresses',
        dispatch,
      })

      return data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)
