import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { fetchLastSellerContract } from '../actions/fetch-last-seller-contract'
import { fetchLastSellerContractDate } from '../actions/fetch-last-seller-contract-date'

export const sellerContractAdaptater = createEntityAdapter()

const sellerContractSlice = createSlice({
  name: 'sellerContract',
  reducers: {},
  initialState: sellerContractAdaptater.getInitialState({
    isDateLoading: false,
    isContractLoading: false,
    isError: false,
    lastSellerContractDate: null,
  }),
  extraReducers: (builder) => {
    builder.addCase(fetchLastSellerContractDate.pending, (state) => {
      state.isDateLoading = true
      state.isError = false
    })
    builder.addCase(fetchLastSellerContractDate.fulfilled, (state, { payload }) => {
      state.isDateLoading = false
      state.lastSellerContractDate = payload
    })
    builder.addCase(fetchLastSellerContractDate.rejected, (state) => {
      state.isDateLoading = false
      state.isError = true
    })

    builder.addCase(fetchLastSellerContract.pending, (state) => {
      state.isError = false
      state.isContractLoading = true
    })
    builder.addCase(fetchLastSellerContract.fulfilled, (state, { payload }) => {
      state.isContractLoading = false
      sellerContractAdaptater.addMany(state, payload)
    })
    builder.addCase(fetchLastSellerContract.rejected, (state) => {
      state.isError = true
      state.isContractLoading = false
    })
  },
})

export default sellerContractSlice.reducer
