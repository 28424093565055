import { memo, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useUser } from '@/contexts/user/User.context'
import { getFromLocalStorage } from '@/helpers/localstorage'

import Dialog from '../ui/Dialog/Dialog'

import { useDismissSellerDataConsentMutation } from './operations.generated'
import { SellerConsentForm } from './SellerConsentForm'

import './SellerConsent.scss'

export const SellerConsentDialog = memo(() => {
  const { t } = useTranslation()

  const { user, isLoading, fetchUser } = useUser()
  const { sellerConfig, username } = user || {}
  const { dataConsent } = sellerConfig || {}
  const { hasReceivedDataConsentRequest } = dataConsent || {}

  const isImpersonating = getFromLocalStorage('isImpersonating')

  const [dismissSellerDataConsent] = useDismissSellerDataConsentMutation()

  const handleClose = useCallback(async () => {
    await dismissSellerDataConsent()
    fetchUser()
  }, [])

  if (isLoading || !user || !sellerConfig || isImpersonating) return null

  return (
    <Dialog
      className="seller-consent"
      isOpen={!hasReceivedDataConsentRequest}
      title={t('gpdrDialogTitle')}
      onClose={handleClose}
    >
      <div className="">
        <Trans
          i18nKey="gpdrDialogText"
          values={{
            username: username || '',
          }}
        />

        <SellerConsentForm emailLabel={t('dialogSellerConsentEmail')} smsLabel={t('dialogSellerConsentSMS')} />
      </div>
    </Dialog>
  )
})
