import { datadogRum } from '@datadog/browser-rum'

import { APP_NAME, APP_VERSION } from '@/config/constants.js'

const { VITE_APP_DATADOG_APP_ID, VITE_APP_DATADOG_APP_ENABLED, VITE_APP_DATADOG_CLIENT_TOKEN, VITE_APP_BITS_ENV } =
  import.meta.env

if (VITE_APP_DATADOG_APP_ID && VITE_APP_DATADOG_CLIENT_TOKEN && VITE_APP_DATADOG_APP_ENABLED === 'true') {
  datadogRum.init({
    applicationId: VITE_APP_DATADOG_APP_ID,
    clientToken: VITE_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: APP_NAME,
    env: VITE_APP_BITS_ENV,
    version: APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}
