// !!!
// This filename & its variable names are a bit cryptic on purpose.
// This is to make less easy to understand what they are supposed to do,
// which is computing a unique fingerprint of the user's browser.
// !!!
type BrowserFingerprint = string
let sv: BrowserFingerprint

export const getStickyVersion = async () => {
  if (typeof window === 'undefined') {
    return null
  }

  if (sv) {
    return sv
  } else {
    const getSV = (await import('get-browser-fingerprint')).default
    sv = getSV({ hardwareOnly: true })
  }

  return sv
}
