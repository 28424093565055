import { createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

import type { RootState } from '@/reducers'

export const fetchLastSellerContractDate = createAsyncThunk(
  'fetch-last-seller-contract-date',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const {
      authentication: { token },
    } = getState() as RootState

    try {
      const { data } = await internalRequest({
        url: `/seller/contract/last-date`,
        headers: { authorization: token, ...(await getAdditionalHeaders()) },
        method: 'GET',
        dispatch,
      })

      return data
    } catch (error: any) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }
    }
  }
)
