export const IMPERSONATION_TOKEN_ID_PARAM_NAME = 'impersonationTokenId'
export const PAYPAL_SSO_PARAM_NAME = 'paypalSSOFlow'
export const MAGIC_TOKEN_QUERY_PARAM_NAME = 'magicToken'

export function getUrlAuthTokens() {
  const queryParams = new URLSearchParams(window.location.search)
  const magicToken = queryParams.get(MAGIC_TOKEN_QUERY_PARAM_NAME)
  const impersonateToken = queryParams.get(IMPERSONATION_TOKEN_ID_PARAM_NAME)
  const paypalSsoToken = queryParams.get(PAYPAL_SSO_PARAM_NAME)

  return { magicToken, impersonateToken, paypalSsoToken }
}
