import type { DetailedHTMLProps, HTMLAttributes } from 'react'

export type BodyVariant = 'callout1' | 'callout2' | 'callout3' | 'callout4' | 'callout5' | 'callout6' | 'callout7'

type BodyHTMLProps = DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>

export interface BodyProps extends BodyHTMLProps {
  variant: BodyVariant
}

export const Callout = ({ variant, className, ...props }: BodyProps) => {
  const base = new Map<string, string>([
    ['callout1', 'text-[14px]'],
    ['callout2', 'font-semibold text-[14px]'],
    ['callout3', 'text-[12px]'],
    ['callout4', 'font-semibold  text-[12px]'],
    ['callout5', 'italic text-[12px]'],
    ['callout6', 'font-semibold  text-[10px]'],
    ['callout7', 'italic text-[10px]'],
  ])

  const classNames = `${base.get(variant)} font-Gantari mb-0 ${className}`

  return <p className={classNames} {...props} />
}
