import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Loader from '@/components/ui/Loader/Loader'
import { getNetworkDownloadSpeedInMBPS } from '@/helpers/getNetworkSpeed'
import { logger, LOGGER_LEVEL } from '@/network/common/logger'

import './ErrorNetworkLoadingPage.scss'

const ErrorNetworkLoadingPage = () => {
  const { t } = useTranslation()

  const logNetworkSpeed = async () => {
    const networkSpeed = await getNetworkDownloadSpeedInMBPS()
    if (parseFloat(networkSpeed) < 100) {
      logger({
        level: LOGGER_LEVEL.WARN,
        message: `Slow network speed detected`,
        meta: { networkSpeedInMbPerSecond: networkSpeed },
      })
    }
  }

  useEffect(() => {
    logNetworkSpeed()
  }, [])

  return (
    <div className="loading-page">
      <Loader />
      <h1>{t('errorNetworkLoadingPageTitle')}</h1>
    </div>
  )
}

export default ErrorNetworkLoadingPage
