import * as Sentry from '@sentry/react'

import { APP_VERSION } from '@/config/constants'

const { VITE_APP_SENTRY_DSN, VITE_APP_BITS_ENV } = import.meta.env
export const IS_SENTRY_ENABLED = !!VITE_APP_SENTRY_DSN

type PropagationTarget = string | RegExp
const tracePropagationTargets = (
  (VITE_APP_BITS_ENV?.includes('local') ? ['localhost'] : []) as PropagationTarget[]
).concat([/^https:\/\/(.*\.)?seller-studio.voggt\.com/])

export const initSentry = () => {
  Sentry.init({
    dsn: VITE_APP_SENTRY_DSN,
    environment: VITE_APP_BITS_ENV || 'production',
    release: APP_VERSION,
    normalizeDepth: 10,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        networkDetailAllowUrls: ['bits-server.production.voggt.io', 'bits-socket.production.voggt.io'],
        minReplayDuration: 10000, // 10 seconds is the bare minimum for a useful replay
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, // 1 = Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // beforeSend(event) {
    //   // ...filter sent errors/events here if necessary...
    //   return event
    // },
  })

  // Set version for the app
  Sentry.setContext('appVersion', {
    appVersion: APP_VERSION,
  })
}

type ErrorLike = {
  message: string
} // TODO: improve this?

// type SecurityLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'

type TrackingOptions = { meta: Record<string, any> }

export const trackError = (error: Error | ErrorLike, options?: TrackingOptions) => {
  const { meta } = options || { meta: {} }

  if (error instanceof Error) {
    Sentry.captureException(error, {
      extra: { ...meta },
    })
  } else {
    Sentry.captureMessage((error as any)?.message || 'Invalid Error', {
      extra: { ...meta, originalError: error },
      level: 'error',
    })
  }
}

export const trackWarning = (message: string, options?: TrackingOptions) => {
  const { meta } = options || { meta: {} }
  Sentry.captureMessage(message, {
    extra: meta,
    level: 'warning',
  })
}

export const trackInfo = (message: string, options?: TrackingOptions) => {
  const { meta } = options || { meta: {} }
  Sentry.captureMessage(message, {
    extra: meta,
    level: 'info',
  })
}
