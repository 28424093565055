import { Typography } from 'antd'
import Link from 'antd/lib/typography/Link'
import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'

import './StripeAutoDebitAgreementContent.scss'

interface StripeAutoDebitAgreementContentProps {
  onSubmit: () => void
  loading: boolean
}

const StripeAutoDebitAgreementContent = ({ onSubmit, loading }: StripeAutoDebitAgreementContentProps) => {
  const { t } = useTranslation()

  const mailto = t('supportMailTo')

  return (
    <div className="stripe-auto-debit-agreement-content flex flex-column w-full">
      <p>
        <Trans
          i18nKey="sepaDebitAgreementExplanation"
          components={{
            1: <br />,
            2: <a className="contact-support-action" href={`mailto:${mailto}`} />,
          }}
          values={{
            mailto,
          }}
        />
      </p>

      <Typography.Text>{t('sepaAcceptDebitAgreementText')}</Typography.Text>

      <Typography.Text className="pt-4" strong>
        {t('sepaAcceptDebitAgreementNotificationText')}
      </Typography.Text>

      <Typography.Text className="pt-4">{t('sepaAcceptDebitAgreementCTAText')}</Typography.Text>

      <div className="flex w-full pt-[32px]">
        <Button
          className="validate-action accept-sepa-debit-agreement-action primary"
          htmlType="submit"
          isLoading={loading}
          label={t('sepaAcceptDebitAgreementButtonText')}
          onClick={onSubmit}
        />
      </div>

      <div className="text-justify pt-4 callout_6">
        <Link href={t('sepaAcceptDebitAgreementLearnMoreUniversityUrl')} rel="noopener noreferrer" target="_blank">
          {t('sepaAcceptDebitAgreementLearnMoreText')}
        </Link>
      </div>
    </div>
  )
}

export default memo(StripeAutoDebitAgreementContent)
