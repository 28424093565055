import axios from 'axios'
import dayjs from 'dayjs'

import Config from '@/config/config'

import type { User } from '@/types'

type RefreshAuthTokenParams = {
  userId?: User['id']
  refreshToken?: string
  tokenExpiresAt?: Date
}

type ReponseRefresh = {
  token?: string
  refreshToken?: string
  tokenExpiresAt: Date
}

export const refreshAuthToken = async ({ userId, refreshToken, tokenExpiresAt }: RefreshAuthTokenParams) => {
  const tokenHasExpired = checkIfTokenHasExpired(tokenExpiresAt, refreshToken)
  const shouldRefreshToken = userId && tokenHasExpired
  if (!shouldRefreshToken) return { refreshToken, tokenExpiresAt } as ReponseRefresh
  try {
    const { data } = await axios.post<ReponseRefresh>(`${Config.SERVER_URL}/login/refresh`, {
      userId,
      refreshToken,
    })
    return data
  } catch (error) {
    // nothing here for the moment
  }
}

const checkIfTokenHasExpired = (
  tokenExpiresAt?: RefreshAuthTokenParams['tokenExpiresAt'],
  refreshToken?: RefreshAuthTokenParams['refreshToken']
) => {
  if (!tokenExpiresAt || !refreshToken) return false
  const tokenExpiresAtDate = dayjs(tokenExpiresAt)

  return dayjs().add(10, 'm') > tokenExpiresAtDate
}
