import { version } from '../../package.json'

// App
export const APP_NAME = 'seller-studio'
export const APP_TITLE = 'Seller Studio'
export const APP_VERSION = version

// Units
export const ONE_MB_IN_BYTES = 1024 * 1024

// Product Images
export const PRODUCT_IMAGES_MAX_IMAGES_PER_PRODUCT = 10
export const PRODUCT_IMAGES_ACCEPTED_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.webp', '.heic', '.heif']
export const PRODUCT_IMAGES_MAX_IMAGE_SIZE_IN_BYTES = 20 * ONE_MB_IN_BYTES // 50 MB
