import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { notificationDanger } from '@/components/ui/Notification/Notification'
import { logger, LOGGER_LEVEL } from '@/network/common/logger'
import { trackWarning } from '@/util/sentry'

import type { ERROR_REASON } from './GenericErrorMessage.container'

interface GenericErrorMessageProps {
  error?: {
    errorMessage: string
    errorReason: ERROR_REASON | undefined
    networkSpeed?: string
  }
}

export const GenericErrorMessage = (props: GenericErrorMessageProps) => {
  const { error } = props
  const { errorMessage, errorReason, networkSpeed } = error || { errorMessage: '', errorReason: undefined }
  const { t } = useTranslation()

  const [errorAlreadyDisplayed, setErrorAlreadyDisplayed] = useState(false)

  useEffect(() => {
    if (!errorAlreadyDisplayed && errorMessage !== '') {
      if (errorReason === 'SERVER_SIDE') {
        notificationDanger(t('genericErrorMessagePleaseTryAgain'))
        trackWarning(errorMessage, { meta: { scope: 'GenericErrorMessage', reason: errorReason } })
      } else if (errorReason === 'CLIENT_SIDE') notificationDanger(t('genericErrorMessageNoInternet'))

      logger({
        level: LOGGER_LEVEL.ERROR,
        message: `${errorMessage} - ${errorReason}`,
        meta: { errorReason, networkSpeedInMbPerSecond: networkSpeed },
      })

      setErrorAlreadyDisplayed(true)
    }
  }, [errorAlreadyDisplayed, errorMessage])

  return null
}
