import { ApolloClient, ApolloLink, createHttpLink, from, gql, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { RetryLink } from '@apollo/client/link/retry'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { sha256 } from 'crypto-hash'
import { createClient } from 'graphql-ws'

import Config from '@/config/config'
import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import generateIntrospection from '@/network/graphql/introspection-result.generated.json'
import { store } from '@/reducers'
import {
  setWebsocketStatus,
  showWebsocketStatus,
  hideWebsocketStatus,
  WS_STATUS,
  setNextTimeBeforeRetry,
  MAX_TIME_BEFORE_RETRY,
  showNetworkError,
} from '@/reducers/network'
import { refreshAuthToken } from '@/util/refresh-auth-token'

import { logger, LOGGER_LEVEL } from '../common/logger'

import handleGenericGraphQLError from './errorsHandler'

import type { NormalizedCacheObject } from '@apollo/client'
import type { KeyArgsFunction, KeySpecifier } from '@apollo/client/cache/inmemory/policies'
import type { Reference } from '@apollo/client/utilities'
import type { RelayFieldPolicy } from '@apollo/client/utilities/policies/pagination'
import type { Store } from '@reduxjs/toolkit'

const { VITE_APP_DEFAULT_TIMEOUT } = Config

export function makeId(length: number = 64) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

const httpLink = createHttpLink({
  uri: `${Config.SERVER_URL}/graphql`,
  fetch: (uri, options) => {
    const uuid = makeId(9)
    //@ts-expect-error: Property body does not exist on type RequestInit
    const operationName = JSON.parse(options.body).operationName
    //@ts-expect-error: Property headers does not exist on type RequestInit
    options.headers['X-Apollo-Operation-Name'] = operationName
    //@ts-expect-error: Property headers does not exist on type RequestInit
    options.headers['X-Apollo-Operation-Id'] = makeId()

    return fetch(uri + '?uuid=' + uuid, options)
  },
})

const timeoutLink = new ApolloLinkTimeout(VITE_APP_DEFAULT_TIMEOUT ? parseInt(VITE_APP_DEFAULT_TIMEOUT) : 20000)
const timeoutHttpLink = timeoutLink.concat(httpLink)

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    jitter: true,
    max: Infinity,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      const { response } = error || {}
      const { status } = response || {}

      // Do not retry 400 errors (Bad Request) or 401 errors (Unauthorized)
      if (status === 400) return false

      if (status === 401) {
        store.dispatch({ type: 'authentication/logout' })
      }

      return error ? false : !_operation.getContext().noRetry
    },
  },
})

export const GET_SERVER_DATE_QUERY = gql`
  query getServerDateQuery {
    server
  }
`

export const initApolloClient = async (store: Store): Promise<ApolloClient<NormalizedCacheObject>> => {
  const additionalHeaders = await getAdditionalHeaders()

  const authLink = setContext(async ({ operationName }, { headers }) => {
    const {
      authentication: { token, user },
    } = store.getState()

    const shouldSendAuthorization = operationName
      ? !['LoginWithImpersonationToken', 'LoginWithMagicToken'].includes(operationName)
      : false

    const authorization = shouldSendAuthorization && token ? { authorization: token } : {}

    return {
      headers: {
        ...headers,
        ...additionalHeaders,
        ...authorization,
        'x-voggt-user-id': user?.id,
      },
    }
  })

  const computeProgressiveRetryTimeout = (retries: number) => {
    if (retries < 50) return 500
    else {
      // Each 50 retries, we add 500ms to the timeout, we cap it at MAX_TIME_BEFORE_RETRY
      const nbOf50Retries = Math.floor(retries / 50) - 1
      const timeToAdd = nbOf50Retries * 500
      return Math.min(timeToAdd, MAX_TIME_BEFORE_RETRY)
    }
  }

  let activeSocket: any, timedOut: any
  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${Config.SOCKET_URL}/graphql`,
      connectionParams: () => {
        const {
          authentication: { token },
        } = store.getState()
        if (token) return { ...additionalHeaders, authorization: token }

        return additionalHeaders
      },
      shouldRetry: () => {
        return true
      },
      retryAttempts: Infinity,
      retryWait: (retries) => {
        const nextTimeout = computeProgressiveRetryTimeout(retries)
        store.dispatch(setNextTimeBeforeRetry(nextTimeout))
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve()
          }, nextTimeout)
        })
      },
      keepAlive: 5_000,
      on: {
        connected: (socket) => {
          activeSocket = socket
          logger({
            level: LOGGER_LEVEL.INFO,
            message: 'Websocket connected',
            meta: {
              eventType: 'WEBSOCKET_OPENED',
            },
          })
          store.dispatch(setWebsocketStatus(WS_STATUS.CONNECTED))

          setTimeout(() => store.dispatch(hideWebsocketStatus()), 5000)
        },
        closed: (event: any) => {
          logger({
            level: LOGGER_LEVEL.INFO,
            message: 'Websocket closed',
            meta: {
              eventType: 'WEBSOCKET_CLOSED',
            },
          })
          store.dispatch(setWebsocketStatus(WS_STATUS.CLOSED))

          if (!event.wasClean) {
            store.dispatch(showWebsocketStatus())
          }
        },
        connecting: () => {
          logger({ level: LOGGER_LEVEL.INFO, message: 'Websocket connecting' })
          store.dispatch(setWebsocketStatus(WS_STATUS.CONNECTING))
        },
        ping: (received) => {
          logger({ level: LOGGER_LEVEL.DEBUG, message: 'Websocket ping' })
          if (!received)
            // sent
            timedOut = setTimeout(() => {
              if (activeSocket?.readyState === WebSocket.OPEN) activeSocket.close(4408, 'Request Timeout')
              logger({
                level: LOGGER_LEVEL.INFO,
                message: 'Websocket ping timeout, closing the socket',
                meta: {
                  eventType: 'WEBSOCKET_PING_TIMEOUT',
                },
              })
            }, 3_000) // wait 2 seconds for the pong and then close the connection
        },
        pong: (received) => {
          logger({ level: LOGGER_LEVEL.DEBUG, message: 'Websocket pong' })
          if (received) clearTimeout(timedOut) // pong is received, clear connection close timeout
        },
      },
    })
  )
  const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ startRequest: new Date() })

    return forward(operation)
  })

  const refreshAuthMiddleware = setContext(async () => {
    const {
      authentication: { user, refreshToken, tokenExpiresAt },
    } = store.getState()

    const data = await refreshAuthToken({
      userId: user?.id,
      refreshToken,
      tokenExpiresAt,
    })

    if (data) {
      if (data.token) {
        store.dispatch({ type: 'authentication/refreshAuthToken', payload: { data } })
      }
    } else {
      store.dispatch({ type: 'authentication/logout' })
    }
  })

  const logTimeLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      const time = new Date().getTime() - operation.getContext().startRequest.getTime()
      operation.setContext({ timeRequest: time })

      return data
    })
  })

  const dateServerLink = new ApolloLink((operation, forward) => {
    const { cache } = operation.getContext()

    return forward(operation).map((data) => {
      const context = operation.getContext()
      const requestDate = context.response.headers.get('Date')
      const serverTime = new Date(requestDate).getTime() + context.timeRequest

      cache.writeQuery({ query: GET_SERVER_DATE_QUERY, data: { server: new Date(serverTime) } })

      return data
    })
  })

  const persistedQueryLink = createPersistedQueryLink({ sha256 })

  const splitLink = persistedQueryLink.concat(
    split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink,
      from([
        refreshAuthMiddleware,
        authLink,
        dateServerLink,
        timeStartLink,
        logTimeLink,
        timeoutHttpLink,
        // httpLink,
      ])
    )
  )

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const uuid = operation.getContext().response?.url.split('?uuid=')[1] || ''
    const meta = { operationName: operation.operationName, uuid }
    const isFailedLoginAttempt =
      operation.operationName === 'Login' && graphQLErrors?.[0]?.extensions?.code === 'UNAUTHENTICATED'
    if (isFailedLoginAttempt) {
      logger({ level: LOGGER_LEVEL.INFO, message: 'Failed login attempt', meta: { ...meta, graphQLErrors } })
      return
    }

    let message = 'Apollo error'
    if (graphQLErrors) {
      message = graphQLErrors.map((error) => error.message).join(', ')
      logger({ level: LOGGER_LEVEL.ERROR, message, meta: { ...meta, graphQLErrors } })
    } else if (networkError) {
      message = 'Network error'
      logger({ level: LOGGER_LEVEL.ERROR, message, meta: { ...meta, networkError } })
    }

    // Handle network errors specifically
    // Warning: According to the Apollo documentation:
    // "An error is passed as a networkError if a link further down the chain called the error callback on the observable. In most cases, graphQLErrors is the errors field of the result from the last next call."
    // This mean that networkError can contain errors that are not really "network errors" but rather GraphQL errors.
    // We then filter out using the status code to at least ignore BAD USER INPUT errors
    // @ts-expect-error: Property statusCode does not exist on type Error | ServerParseError | ServerError // TODO: Fix properly & remove this?
    if (networkError && networkError?.statusCode !== 400) {
      store.dispatch(showNetworkError())
    }

    if (graphQLErrors)
      graphQLErrors?.forEach((error) => {
        const errorCode = error?.extensions?.code

        // Redirect to login page if the user is unauthenticated
        if (errorCode === 'UNAUTHENTICATED') {
          // TODO: get the current page url and redirect to it after re-login
          location.href = '/logout'
          return
        }

        handleGenericGraphQLError(error)
      })
  })

  return new ApolloClient({
    link: from([errorLink, retryLink, splitLink]),
    cache: new InMemoryCache({
      possibleTypes: generateIntrospection.possibleTypes,
      typePolicies: {
        Show: {
          fields: {
            customers: fixedRelayStylePagination(),
            orderedProducts: fixedRelayStylePagination(),
            presentUsers: fixedRelayStylePagination(),
            ShowFeedItemConnection: fixedRelayStylePagination(),
          },
        },
        User: {
          fields: {
            pastAndCurrentShowsByStartDateDesc: fixedRelayStylePagination(),
            sellerConfig: { merge: true },
            allModerators: fixedRelayStylePagination(),
          },
        },
        Query: {
          fields: {
            getSellerShipmentsByStatus: fixedRelayStylePagination(['statuses']),
            getSellerCancelledAndRefundedOrders: fixedRelayStylePagination(),
            customersWithUnshippedOrderedProducts: fixedRelayStylePagination(['sort']),
          },
        },
        OrderedProduct: {
          fields: {
            order: {
              merge: true,
            },
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        // TODO: shouldn't this rather be 'no-cache'?
        // Most of data fetched in the studio are updated frequently
        // outside of the Studio. To make sure displayed data are up
        // to date, 'cache-and-network' seemed like a better option.
        // See https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
        fetchPolicy: 'cache-and-network',
      },
    },
  })
}

// see https://github.com/apollographql/apollo-client/issues/7944
const fixedRelayStylePagination = (keyArgs?: false | KeySpecifier | KeyArgsFunction): RelayFieldPolicy<Reference> => {
  const { read, merge, ...rest } = relayStylePagination(keyArgs)
  return {
    ...rest,
    read: (existing, options) => {
      if (!existing?.edges) return existing
      return read!(existing, options)
    },
    // @ts-expect-error TODO: Fix properly & remove this
    merge: (existing, incoming, options) => {
      if (!incoming?.edges) return incoming

      const _existing = existing
        ? {
            ...existing,
            edges: existing.edges ?? [],
          }
        : existing
      return typeof merge === 'boolean' ? merge : merge!(_existing, incoming, options)
    },
  }
}
