import { APP_NAME, APP_VERSION } from '@/config/constants'

import { dependencies } from '../../package.json'
const {
  VITE_APP_BITS_ENV,
  VITE_APP_SERVER_URL,
  VITE_APP_SOCKET_URL,
  VITE_APP_CLIENT_LOGS_LEVEL,
  VITE_APP_CLIENT_MONITORING_URL,
} = import.meta.env

const Config: { [key: string]: string } = {
  VITE_APP_BITS_ENV,
  PLATFORM: APP_NAME,
  PLATFORM_VERSION: APP_VERSION,
  NETWORK_LIB: 'apollo',
  NETWORK_LIB_VERSION: dependencies['@apollo/client'],
  VITE_APP_CLIENT_LOGS_LEVEL,
  VITE_APP_CLIENT_MONITORING_URL,
}

if (VITE_APP_BITS_ENV === 'production') {
  Config.SERVER_URL = VITE_APP_SERVER_URL || 'https://bits-server.production.voggt.io'
  Config.SOCKET_URL = VITE_APP_SOCKET_URL || 'wss://bits-socket.production.voggt.io'
} else {
  Config.SERVER_URL = VITE_APP_SERVER_URL || 'http://localhost:3000'
  Config.SOCKET_URL = VITE_APP_SOCKET_URL || 'ws://localhost:3030'
}

export const isDevEnv = VITE_APP_BITS_ENV === 'development' || VITE_APP_BITS_ENV.includes('local')
export const isProdEnv = VITE_APP_BITS_ENV === 'production'

export default Config

export const DEFAULT_COUNTRY_ID = 'FR'
