import { applyMiddleware, compose, combineReducers, createStore } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { isProdEnv } from '@/config/config'

import authentication from './authentication'
import network from './network'
import sellerContract from './seller-contract'
import shows from './shows'

import type { TypedUseSelectorHook } from 'react-redux'

const persistConfig = {
  whitelist: ['authentication'],
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  authentication,
  sellerContract,
  shows,
  network,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewares = [thunk]

const composeEnhancers: any = isProdEnv ? composeWithDevTools({ trace: true }) : compose

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)))
const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { persistor, store }
