import { notificationDanger } from '@/components/ui/Notification/Notification'
import { trackError } from '@/util/sentry'

import type { GraphQLFormattedError } from 'graphql/error'

type ErrorExtensions = {
  code?: string
  meta?: { error?: { message?: string; description?: string } }
  translationKey?: string
}

// Error codes that we don't want to track
const knownErrorCodes: Array<string> = [
  'BAD_USER_INPUT',
  'STRIPE_SELLER_CREATION_ERROR',
  'STRIPE_FILE_UPLOAD_ERROR',
  'SENDCLOUD_SHIPPING_CREATION_ERROR',
  'SENDCLOUD_SHIPPING_CREATION_GET_PICKUP_ERROR',
  'SENDCLOUD_SHIPPING_CREATION_NO_PICKUP_FOUND_ERROR',
  'SENDCLOUD_SHIPPING_ESTIMATION_CANT_GET_SHIPPING_METHODS_ERROR',
  'SENDCLOUD_SHIPPING_ESTIMATION_CANT_GET_PRICES_ERROR',
  'BOXTAL_SHIPPING_CREATION_ERROR',
  'SELLER_CANT_LIST_STRIPE_TRANSACTIONS',
  'ZENDESK_TICKET_CREATION_ERROR',
  'ZENDESK_TICKET_TAGS_ADD',
  'TICKET_CREATOR_CANT_FIND_SHOW',
  'TICKET_CREATOR_CANT_SELLER_SHOW',
]

// we don't want to track it on sentry and will display errors direclty in the view
const codeToNotTrack: Array<string> = ['NOT_FOUND', 'FORBIDDEN']

const shouldDisplayError = (graphqlError: GraphQLFormattedError) => {
  const { extensions } = graphqlError
  const { code: errorCode, translationKey } = (extensions as ErrorExtensions) || {}

  // Ignore BAD_USER_INPUT errors without translations that are just plain GraphQL errors
  // probably from a developer mistake
  if (errorCode === 'BAD_USER_INPUT' && !translationKey) {
    return false
  }

  // return isDevEnv || knownErrorCodes.includes(errorCode || '')
  return knownErrorCodes.includes(errorCode || '')
}

const handleGenericGraphQLError = (graphqlError: GraphQLFormattedError) => {
  const { extensions, message } = graphqlError
  const { code: errorCode, meta } = (extensions as ErrorExtensions) || {}
  const errorMessage = message || meta?.error?.message || meta?.error?.description

  if (!errorMessage) {
    return
  }

  // TODO: For the following part, shouldn't each view decide how to handle it errors?
  // Only display known errors
  const shouldErrorBeDisplayed = shouldDisplayError(graphqlError)

  if (!shouldErrorBeDisplayed) {
    if (codeToNotTrack.includes(errorCode || '')) return
    trackError(graphqlError, { meta: { errorCode, scope: 'handleGenericGraphQLError' } })
  } else {
    notificationDanger(errorMessage)
  }
}

export default handleGenericGraphQLError
