import { createEntityAdapter, createSlice, createAction } from '@reduxjs/toolkit'

import { fetchAllShows } from '../actions/fetch-all-shows'
import { fetchShow } from '../actions/fetch-show'
import { fetchShows } from '../actions/fetch-shows'
import { removeShow } from '../actions/remove-show'
import { updateShow } from '../actions/update-show'
import { uploadShowThumbnail } from '../actions/upload-show-thumbnail'

import type { Show } from '../types'

export const showsAdapter = createEntityAdapter<Show>()

export const resetShowsState = createAction('resetShowsState')

const initialState = showsAdapter.getInitialState({
  isLoading: false,
  isCreateLoading: false,
  isThumbnailLoading: false,
  error: {},
})

const showSlice = createSlice({
  name: 'shows',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShows.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchShows.fulfilled, (state, action) => {
        showsAdapter.removeAll(state)
        showsAdapter.addMany(state, action.payload)
        state.isLoading = false
      })
      .addCase(fetchAllShows.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAllShows.fulfilled, (state, { payload }) => {
        showsAdapter.removeAll(state)
        showsAdapter.addMany(state, payload)
        state.isLoading = false
      })
      .addCase(fetchShow.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchShow.fulfilled, (state, { payload }) => {
        showsAdapter.upsertOne(state, payload)
        state.isLoading = false
      })
      .addCase(removeShow.fulfilled, (state, { payload }) => {
        showsAdapter.removeOne(state, payload)
      })
      .addCase(uploadShowThumbnail.pending, (state) => {
        state.isThumbnailLoading = true
      })
      .addCase(uploadShowThumbnail.fulfilled, (state, { payload }) => {
        showsAdapter.upsertOne(state, payload)
        state.isThumbnailLoading = false
      })
      .addCase(uploadShowThumbnail.rejected, (state) => {
        state.isThumbnailLoading = false
      })
      .addCase(updateShow.fulfilled, (state, { payload }) => {
        showsAdapter.upsertOne(state, payload)
      })
      .addCase(resetShowsState, () => ({ ...initialState, isLoading: true }))
  },
})

export default showSlice.reducer
