import { createAsyncThunk } from '@reduxjs/toolkit'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

import type { RootState } from '@/reducers'

export const fetchShows = createAsyncThunk('fetch-shows', async (_, { rejectWithValue, getState, dispatch }) => {
  const {
    authentication: { token, user },
  } = getState() as RootState

  if (!user) return

  try {
    const { data } = await internalRequest({
      url: `/shows?userId=${user.id}`,
      headers: { authorization: token, ...(await getAdditionalHeaders()) },
      method: 'GET',
      dispatch,
    })

    return data
  } catch (error: any) {
    return rejectWithValue(error.response.data)
  }
})
