import { createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

import type { RootState } from '@/reducers'

export const fetchAllShows = createAsyncThunk('fetch-all-shows', async (_, { rejectWithValue, getState, dispatch }) => {
  const {
    authentication: { token },
  } = getState() as RootState

  try {
    const { data } = await internalRequest({
      headers: { authorization: token, ...(await getAdditionalHeaders()) },
      method: 'GET',
      url: `/shows`,
      dispatch,
    })

    return data
  } catch (error: any) {
    if (isAxiosError(error)) {
      return rejectWithValue(error.response?.data)
    }
  }
})
