import { createAsyncThunk } from '@reduxjs/toolkit'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

import type { RootState } from '@/reducers'

export const removeShow = createAsyncThunk(
  'remove-show',
  async (showId: number, { rejectWithValue, getState, dispatch }) => {
    const {
      authentication: { token },
    } = getState() as RootState

    try {
      await internalRequest({
        url: `/shows/${showId}`,
        headers: { authorization: token, ...(await getAdditionalHeaders()) },
        method: 'DELETE',
        dispatch,
      })

      return showId
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)
