import Config from '@/config/config'

const { VITE_APP_CLIENT_MONITORING_URL } = Config

const checkDownloadSpeed = async (baseUrl: string, fileSizeInMb: number) => {
  const startTime = performance.now()
  const bitsLoaded = fileSizeInMb * 8 * 1000000

  try {
    const response = await fetch(`${baseUrl}`)
    if (!response.ok) return 'unable to fetch'

    const endTime = performance.now()
    const duration = (endTime - startTime) / 1000
    const speedBps = bitsLoaded / duration
    const speedMbps = speedBps / 1000000

    return speedMbps.toFixed(2)
  } catch (error) {
    return 'error fetching'
  }
}

export const getNetworkDownloadSpeedInMBPS = async () => {
  return await checkDownloadSpeed(VITE_APP_CLIENT_MONITORING_URL, 5)
}
