import { NetworkBadge } from '../NetworkBadge/NetworkBadge'

import type { WS_STATUS } from '../../../reducers/network'

interface NetworkManagerContainerProps {
  status: WS_STATUS
  showWebsocketStatus: boolean
}

export const NetworkManager = ({ status, showWebsocketStatus }: NetworkManagerContainerProps) => {
  return <NetworkBadge showWebsocketStatus={showWebsocketStatus} status={status} />
}
