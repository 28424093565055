import * as Types from '../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShouldAcceptStripeTermsOfServicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetShouldAcceptStripeTermsOfServicesQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, sellerConfig?: { __typename?: 'SellerConfig', shouldAcceptStripeTermsOfServices: boolean } | null } | null };

export type AcceptStripeTermsOfServicesMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type AcceptStripeTermsOfServicesMutation = { __typename?: 'Mutation', acceptStripeTermsOfServices: { __typename?: 'AcceptStripeTermsOfServicesPayload', ok: boolean } };


export const GetShouldAcceptStripeTermsOfServicesDocument = gql`
    query GetShouldAcceptStripeTermsOfServices {
  viewer {
    id
    sellerConfig {
      shouldAcceptStripeTermsOfServices
    }
  }
}
    `;

/**
 * __useGetShouldAcceptStripeTermsOfServicesQuery__
 *
 * To run a query within a React component, call `useGetShouldAcceptStripeTermsOfServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShouldAcceptStripeTermsOfServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShouldAcceptStripeTermsOfServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShouldAcceptStripeTermsOfServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetShouldAcceptStripeTermsOfServicesQuery, GetShouldAcceptStripeTermsOfServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShouldAcceptStripeTermsOfServicesQuery, GetShouldAcceptStripeTermsOfServicesQueryVariables>(GetShouldAcceptStripeTermsOfServicesDocument, options);
      }
export function useGetShouldAcceptStripeTermsOfServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShouldAcceptStripeTermsOfServicesQuery, GetShouldAcceptStripeTermsOfServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShouldAcceptStripeTermsOfServicesQuery, GetShouldAcceptStripeTermsOfServicesQueryVariables>(GetShouldAcceptStripeTermsOfServicesDocument, options);
        }
export function useGetShouldAcceptStripeTermsOfServicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetShouldAcceptStripeTermsOfServicesQuery, GetShouldAcceptStripeTermsOfServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShouldAcceptStripeTermsOfServicesQuery, GetShouldAcceptStripeTermsOfServicesQueryVariables>(GetShouldAcceptStripeTermsOfServicesDocument, options);
        }
export type GetShouldAcceptStripeTermsOfServicesQueryHookResult = ReturnType<typeof useGetShouldAcceptStripeTermsOfServicesQuery>;
export type GetShouldAcceptStripeTermsOfServicesLazyQueryHookResult = ReturnType<typeof useGetShouldAcceptStripeTermsOfServicesLazyQuery>;
export type GetShouldAcceptStripeTermsOfServicesSuspenseQueryHookResult = ReturnType<typeof useGetShouldAcceptStripeTermsOfServicesSuspenseQuery>;
export type GetShouldAcceptStripeTermsOfServicesQueryResult = Apollo.QueryResult<GetShouldAcceptStripeTermsOfServicesQuery, GetShouldAcceptStripeTermsOfServicesQueryVariables>;
export const AcceptStripeTermsOfServicesDocument = gql`
    mutation AcceptStripeTermsOfServices {
  acceptStripeTermsOfServices {
    ok
  }
}
    `;
export type AcceptStripeTermsOfServicesMutationFn = Apollo.MutationFunction<AcceptStripeTermsOfServicesMutation, AcceptStripeTermsOfServicesMutationVariables>;

/**
 * __useAcceptStripeTermsOfServicesMutation__
 *
 * To run a mutation, you first call `useAcceptStripeTermsOfServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptStripeTermsOfServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptStripeTermsOfServicesMutation, { data, loading, error }] = useAcceptStripeTermsOfServicesMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptStripeTermsOfServicesMutation(baseOptions?: Apollo.MutationHookOptions<AcceptStripeTermsOfServicesMutation, AcceptStripeTermsOfServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptStripeTermsOfServicesMutation, AcceptStripeTermsOfServicesMutationVariables>(AcceptStripeTermsOfServicesDocument, options);
      }
export type AcceptStripeTermsOfServicesMutationHookResult = ReturnType<typeof useAcceptStripeTermsOfServicesMutation>;
export type AcceptStripeTermsOfServicesMutationResult = Apollo.MutationResult<AcceptStripeTermsOfServicesMutation>;
export type AcceptStripeTermsOfServicesMutationOptions = Apollo.BaseMutationOptions<AcceptStripeTermsOfServicesMutation, AcceptStripeTermsOfServicesMutationVariables>;