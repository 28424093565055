import { createAsyncThunk } from '@reduxjs/toolkit'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

export const fetchShow = createAsyncThunk(
  'fetch-show',
  async (showId: number, { rejectWithValue, getState, dispatch }) => {
    const {
      authentication: { token },
    } = getState() as { authentication: { token: string } }

    try {
      const { data } = await internalRequest({
        url: `/shows/${showId}`,
        headers: { authorization: token, ...(await getAdditionalHeaders()) },
        method: 'GET',
        dispatch,
      })

      return data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)
