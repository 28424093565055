import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from '@/contexts/user/User.context'

import Field from '../Form/Field/Field'
import Form from '../Form/Form'
import Button from '../ui/Button/Button'
import { notificationDanger, notificationSuccess } from '../ui/Notification/Notification'

import { useSetSellerDataConsentMutation } from './operations.generated'

interface SellerConsentFormProps {
  emailLabel: string
  smsLabel: string
}

export const SellerConsentForm = (props: SellerConsentFormProps) => {
  const { emailLabel, smsLabel } = props
  const { t } = useTranslation()

  const { user, fetchUser } = useUser()
  const { sellerConfig } = user || {}
  const { dataConsent } = sellerConfig || {}
  const { hasGivenConsentForEmailsAt, hasGivenConsentForSMSAt } = dataConsent || {}

  const [isEmailChecked, setIsEmailChecked] = useState<boolean>(!!hasGivenConsentForEmailsAt)
  const [isSmsChecked, setIsSmsChecked] = useState<boolean>(!!hasGivenConsentForSMSAt)

  const [setSellerDataConsent] = useSetSellerDataConsentMutation()

  const handleSubmit = useCallback(() => {
    setSellerDataConsent({
      variables: {
        input: {
          sellerHasGivenConsentForEmails: isEmailChecked,
          sellerHasGivenConsentForSMS: isSmsChecked,
        },
      },
      onCompleted: () => {
        notificationSuccess(t('sellerConsentSuccess'))
        fetchUser()
      },
      onError: (error) => {
        notificationDanger(error?.message)
      },
    })
  }, [isEmailChecked, isSmsChecked])

  return (
    <Form className="seller-consent-form">
      <Field
        checked={isEmailChecked}
        label={emailLabel}
        name="email"
        type="checkbox"
        onChange={() => setIsEmailChecked(!isEmailChecked)}
      />
      <Field
        checked={isSmsChecked}
        label={smsLabel}
        name="sms"
        type="checkbox"
        onChange={() => setIsSmsChecked(!isSmsChecked)}
      />

      <div className="seller-consent-form-button">
        <Button className="primary" label={t('sellerConsentSave')} onClick={handleSubmit} />
      </div>
    </Form>
  )
}
