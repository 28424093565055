import { createAsyncThunk } from '@reduxjs/toolkit'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

import type { RootState } from '@/reducers'

export const fetchMe = createAsyncThunk('fetch-me', async (_, { rejectWithValue, getState, dispatch }) => {
  const {
    authentication: { token },
  } = getState() as RootState

  try {
    const { data } = await internalRequest({
      headers: { authorization: token, ...(await getAdditionalHeaders()) },
      method: 'GET',
      url: '/me',
      dispatch,
    })

    return data
  } catch (error: any) {
    return rejectWithValue(error.response.data)
  }
})
