import { createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

import type { RootState } from '@/reducers'

export const uploadShowThumbnail = createAsyncThunk(
  'upload-show-thumbnail',
  async ({ showId, thumbnail }: { showId: number; thumbnail: File }, { rejectWithValue, getState, dispatch }) => {
    const {
      authentication: { token },
    } = getState() as RootState

    const form = new FormData()
    form.append('buffer', thumbnail)

    try {
      const { data } = await internalRequest({
        headers: {
          'content-type': 'multipart/form-data',
          authorization: token,
          ...(await getAdditionalHeaders()),
        },
        url: `/shows/${showId}/upload`,
        method: 'POST',
        data: form,
        dispatch,
      })

      return data
    } catch (error: any) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }
    }
  }
)
