import { useTranslation } from 'react-i18next'

import './Loader.scss'

export type LoaderProps = unknown

const Loader = (_: LoaderProps) => {
  const { t } = useTranslation()

  return (
    <span className="is-loading">
      <svg className="icon" fill="none" viewBox="0 0 16 16">
        <path
          d="M7.99992 1.83366C11.4059 1.83366 14.1666 4.59433 14.1666 8.00033C14.1666 11.4063 11.4059 14.167 7.99992 14.167C4.59392 14.167 1.83325 11.4063 1.83325 8.00033"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </svg>
      <span className="label">{t('commonLoading')}</span>
    </span>
  )
}

export default Loader
