import { ToastContainer, toast } from 'react-toastify'

import type { ReactNode } from 'react'

import 'react-toastify/dist/ReactToastify.css'

import './Notification.scss'

const NOTIFICATION_DURATION_DEFAULT_IN_MS = 5000

const NotificationTypeToClassNameMap = {
  default: 'default',
  info: 'info',
  warning: 'warning',
  danger: 'danger',
  success: 'success',
}

type NotificationOptions = {
  type?: keyof typeof NotificationTypeToClassNameMap
  emphasis?: 'low' | 'high'
  title?: string
  duration?: number
  closable?: boolean
}

const buildNotification = (message: ReactNode, options?: NotificationOptions) => {
  const {
    type = 'default',
    emphasis,
    title,
    duration = NOTIFICATION_DURATION_DEFAULT_IN_MS,
    closable = true,
  } = options || {}

  const className = `notification ${NotificationTypeToClassNameMap[type]} ${emphasis || ''}`
  const toastOptions = {
    className,
    autoClose: closable ? duration : (false as const),
  }

  return toast(
    <>
      {title && <p className="title">{title}</p>}
      {message}
    </>,
    toastOptions
  )
}

export const notification = (message: ReactNode, options?: NotificationOptions) => {
  return buildNotification(message, { ...options, type: 'default' })
}
export const notificationInfo = (message: ReactNode, options?: NotificationOptions) => {
  return buildNotification(message, { ...options, type: 'info' })
}
export const notificationWarning = (message: ReactNode, options?: NotificationOptions) => {
  return buildNotification(message, { ...options, type: 'warning' })
}
export const notificationDanger = (message: ReactNode, options?: NotificationOptions) => {
  return buildNotification(message, { ...options, type: 'danger' })
}
export const notificationSuccess = (message: ReactNode, options?: NotificationOptions) => {
  return buildNotification(message, { ...options, type: 'success' })
}

export const NotificationContainer = () => {
  return (
    <ToastContainer
      autoClose={10000}
      hideProgressBar={false}
      pauseOnFocusLoss={false}
      position="top-right"
      theme="light"
      icon={
        //replace by our custom icon when available
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M7.66988 1.99921H16.3399C19.7299 1.99921 21.9999 4.37921 21.9999 7.91921V16.0902C21.9999 19.6202 19.7299 21.9992 16.3399 21.9992H7.66988C4.27988 21.9992 1.99988 19.6202 1.99988 16.0902V7.91921C1.99988 4.37921 4.27988 1.99921 7.66988 1.99921ZM11.9899 9.06021C11.5199 9.06021 11.1299 8.66921 11.1299 8.19021C11.1299 7.70021 11.5199 7.31021 12.0099 7.31021C12.4899 7.31021 12.8799 7.70021 12.8799 8.19021C12.8799 8.66921 12.4899 9.06021 11.9899 9.06021ZM12.8699 15.7802C12.8699 16.2602 12.4799 16.6502 11.9899 16.6502C11.5099 16.6502 11.1199 16.2602 11.1199 15.7802V11.3602C11.1199 10.8792 11.5099 10.4802 11.9899 10.4802C12.4799 10.4802 12.8699 10.8792 12.8699 11.3602V15.7802Z"
            fill="#171514"
            fillRule="evenodd"
          />
        </svg>
      }
      closeOnClick
      draggable
      newestOnTop
      pauseOnHover
    />
  )
}
