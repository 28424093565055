import { createAsyncThunk } from '@reduxjs/toolkit'

import { internalRequest } from '../util/request'

import type { RootState } from '../reducers'

export const updateSellerConfig = createAsyncThunk(
  'update-seller-config',
  async (
    { values, userId }: { values: Record<string, any>; userId: number },
    { rejectWithValue, getState, dispatch }
  ) => {
    const {
      authentication: { token },
    } = getState() as RootState

    try {
      const { data } = await internalRequest({
        headers: {
          authorization: token,
        },
        url: `/seller/config`,
        method: 'PUT',
        data: { values, userId },
        dispatch,
      })

      return data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)
