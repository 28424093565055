import { useTranslation } from 'react-i18next'

import { WS_STATUS } from '@/reducers/network'

import { Callout } from '../../Typography/Callout'

interface NetworkBadgeProps {
  status: WS_STATUS
  showWebsocketStatus: boolean
}

export const NetworkBadge = ({ status, showWebsocketStatus }: NetworkBadgeProps) => {
  const { t } = useTranslation()
  const backgroundColor = new Map<string, string>([
    [WS_STATUS.CLOSED, 'bg-error-red'],
    [WS_STATUS.CONNECTING, 'bg-news-pastel'],
    [WS_STATUS.CONNECTED, 'bg-positive-accent'],
  ])

  if (!showWebsocketStatus) return null

  const classNames = `w-[17px] h-[17px] rounded-full ${backgroundColor.get(status)}`

  return (
    <div className="fixed bottom-4 right-4 rounded-full p-2 flex items-center gap-x-2 bg-black-140 text-offwhite-150">
      <div className={classNames} />
      <Callout className="text-current" variant="callout3">
        {t(`${status}NetworkBadge`)}
      </Callout>
    </div>
  )
}
