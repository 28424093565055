import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import TRANSLATIONS_DE from './de.json'
import TRANSLATIONS_EN from './en.json'
import TRANSLATIONS_FR from './fr.json'

const languageDetector = new LanguageDetector(null, { order: ['navigator'] })
// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    // to enable compatibility with Lokalize CLI
    // see https://github.com/lokalise/lokalise-cli-2-go/issues/89
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    returnEmptyString: false,
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
      de: {
        translation: TRANSLATIONS_DE,
      },
    },
  })
