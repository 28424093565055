export const getFromLocalStorage = (key: string) => {
  try {
    return localStorage?.getItem(key) || null
  } catch {
    return null
  }
}

export const getFromArrayLocalStorage = (key: string, id: number | string) => {
  try {
    const data = localStorage?.getItem(key) || null
    if (data) {
      const parsedData = JSON.parse(data)
      return !!parsedData[id]
    }
  } catch {
    return null
  }
}

export const setToLocalStorage = (key: string, value: string) => {
  try {
    localStorage?.setItem(key, value)
  } catch {
    return void 0
  }
}

export const setToArrayLocalStorage = (key: string, value: number | string) => {
  try {
    const data = localStorage?.getItem(key) || null
    if (data) {
      const parsedData = JSON.parse(data)
      parsedData[value] = true
      localStorage?.setItem(key, JSON.stringify(parsedData))
    } else {
      localStorage?.setItem(key, JSON.stringify({ [value]: true }))
    }
  } catch {
    return void 0
  }
}

export const removeFromLocalStorage = (key: string) => {
  try {
    localStorage?.removeItem(key)
  } catch {
    return void 0
  }
}
