import { Modal, message } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'

import { useUser } from '@/contexts/user/User.context'

import { useAcceptStripeDebitAgreementMutation } from '../operations.generated'
import StripeAutoDebitAgreementContent from '../StripeAutoDebitAgreementContent/StripeAutoDebitAgreementContent'
import { STRIPE_DEBIT_AGREEMENT_START_DATETIME } from '../utils/StripeAutoDebitAgreementUtils'

import './StripeAutoDebitAgreementModal.scss'

export const StripeAutoDebitAgreementModal = () => {
  const { t } = useTranslation()

  const { user, fetchUser } = useUser()
  const { sellerConfig } = user || {}
  const { shouldAcceptStripeDebitAgreement = false, canSkipStripeDebitAgreement = false } = sellerConfig || {}

  const [isOpen, setIsOpen] = useState(false)

  const [acceptStripeDebitAgreement, { loading }] = useAcceptStripeDebitAgreementMutation({
    onError: (error) => {
      message.error(error.message)
    },
  })

  const now = Date.now()
  const shouldRequestUserAgreement = shouldAcceptStripeDebitAgreement && now >= STRIPE_DEBIT_AGREEMENT_START_DATETIME

  const handleSubmit = useCallback(async () => {
    const { data } = await acceptStripeDebitAgreement()
    if (!data?.acceptStripeDebitAgreement?.ok) {
      return
    }

    setIsOpen(false)
    await fetchUser()
  }, [acceptStripeDebitAgreement, fetchUser])

  const onCancel = () => setIsOpen(false)

  useEffect(() => {
    if (shouldRequestUserAgreement) {
      setIsOpen(true)
    }
  }, [shouldRequestUserAgreement])

  return (
    <Modal
      className="stripe-auto-debit-agreement-modal"
      closable={canSkipStripeDebitAgreement}
      closeIcon={<FaXmark />}
      footer={null}
      open={isOpen}
      zIndex={2000}
      destroyOnClose
      onCancel={onCancel}
    >
      <div className="title">{t('sepaAcceptDebitAgreementTitle')}</div>
      <StripeAutoDebitAgreementContent loading={loading} onSubmit={handleSubmit} />
    </Modal>
  )
}
