import { createSlice } from '@reduxjs/toolkit'

import { fetchMe } from '../actions/fetch-me'
import { fetchSellerBillingData } from '../actions/fetch-seller-billing-data'
import { fetchShippingAddress } from '../actions/fetch-shipping-address'
import { updateSellerConfig } from '../actions/update-seller-config'

import type { User } from '../types'

type ActionsPayload = {
  storeToken: { payload: { token: string; refreshToken?: string; tokenExpiresAt?: Date } }
}

interface AuthenticationState {
  hasSignedLastSellerContract: boolean | null
  registerToken: string | null
  credential: string | null
  billingData: {
    data?: Record<string, any>
    isLoading?: boolean
  } | null
  method: string | null
  token: string | null
  isLogged: boolean
  user?: User
  refreshToken?: string
  tokenExpiresAt?: Date
}

const initialState: AuthenticationState = {
  hasSignedLastSellerContract: null,
  registerToken: null,
  credential: null,
  billingData: null,
  user: undefined,
  isLogged: false,
  method: null,
  token: null,
  refreshToken: undefined,
  tokenExpiresAt: undefined,
}

const authenticationSlice = createSlice({
  name: 'authentication',
  reducers: {
    logout: (state) => {
      state.isLogged = false
      state.token = null
      state.user = undefined
      state.hasSignedLastSellerContract = false
    },
    signLastContract: (state, { payload }) => {
      state.hasSignedLastSellerContract = payload
    },
    storeToken: (state, { payload }: ActionsPayload['storeToken']) => {
      state.isLogged = true
      state.token = payload.token
      state.refreshToken = payload.refreshToken
      state.tokenExpiresAt = payload.tokenExpiresAt
    },
    refreshAuthToken: (state, { payload }) => {
      // Warning : the refreshAuthToken action is dispatched from 2 places
      // and can be nested into a data attribute, or not.
      // Was the root cause of this issue : "TypeError Cannot read properties of undefined (reading 'token') "
      const token = payload?.data?.token || payload.token
      const tokenExpiresAt = payload?.data?.tokenExpiresAt || payload.tokenExpiresAt

      state.token = token
      state.tokenExpiresAt = tokenExpiresAt
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMe.fulfilled, (state, { payload }) => {
      state.user = payload
    })
    builder.addCase(updateSellerConfig.fulfilled, (state, { payload }) => {
      if (state.user) state.user.sellerConfig = payload
    })
    builder.addCase(fetchShippingAddress.fulfilled, (state, { payload }) => {
      if (state.user) state.user.shippingAddress = payload[0]
    })
    builder.addCase(fetchSellerBillingData.pending, (state) => {
      state.billingData = { isLoading: true }
    })
    builder.addCase(fetchSellerBillingData.fulfilled, (state, { payload }) => {
      state.billingData = {
        isLoading: false,
        data: payload,
      }
    })
    builder.addCase(fetchSellerBillingData.rejected, (state) => {
      state.billingData = { isLoading: false }
    })
  },
})

export const { logout, signLastContract, storeToken } = authenticationSlice.actions

export default authenticationSlice.reducer
